import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import * as am5plugins_json from "@amcharts/amcharts5/plugins/json";
//import * as am5plugins_json from "../TMPSerializer";


// import { DropdownControl, IDropdownControlItem } from "./DropdownControl";

import type { MapViewer } from "./MapViewer";

export interface IMapSerializerSettings extends am5.IEntitySettings {
	editor: MapViewer;
	saveZoom?: boolean;
}

export interface IMapSerializerPrivate extends am5.IEntityPrivate {
}

export interface IMapSerializerEvents extends am5.IEntityEvents {
}

/**
 * Creates a Button for line drawing tool
 */
export class MapSerializer extends am5.Entity {
	public static className: string = "MapSerializer";
	public static classNames: Array<string> = am5.Entity.classNames.concat([MapSerializer.className]);

	declare public _settings: IMapSerializerSettings;
	declare public _privateSettings: IMapSerializerPrivate;
	declare public _events: IMapSerializerEvents;

	protected _afterNew() {
		super._afterNew();

		this._defaultThemes = this.getEditor()._defaultThemes;
		this._applyThemes();

		// const l = this._root.language;
		// const editor = this.getEditor();
	}

	public _changed() {
		super._changed();
	}

	public _dispose() {
		super._dispose();
	}

	public exportData(): string {
		return JSON.stringify(this.exportJson(), undefined, "  ");
	}

	public exportJson(): any {
		const editor = this.getEditor();

		// Prepare home zoom positions
		//console.log(editor.map.geoPoint());
		//editor.map.set("home")

		if (this.get("saveZoom")) {
			editor.map.setAll({
				homeGeoPoint: editor.map.geoPoint(),
				homeZoomLevel: editor.map.get("zoomLevel"),
				homeRotationX: editor.map.get("rotationX"),
				homeRotationY: editor.map.get("rotationY")
			});
		}
		else {
			editor.map.remove("homeGeoPoint");
			editor.map.remove("homeZoomLevel");
			editor.map.remove("homeRotationX");
			editor.map.remove("homeRotationY");
		}

		const exportSettings: { [index: string]: am5.Entity | am5.Template<any> } = {
			"editor": editor,
			"editor.map": editor.map,

			"editor.pointTemplate": editor.pointTemplate,
			"editor.bubbleTemplate": editor.bubbleTemplate,
			"editor.pixelTemplate": editor.pixelTemplate,
			"editor.linePointTemplate": editor.linePointTemplate,
			"editor.labelTemplate": editor.labelTemplate,

			"editor.polygonSeries": editor.polygonSeries,
			"editor.lineSeries": editor.lineSeries,
			"editor.pointSeries": editor.pointSeries,
			"editor.labelSeries": editor.labelSeries,
			"editor.bubbleSeries": editor.bubbleSeries,
			"editor.gridSeries": editor.gridSeries,
			"editor.backgroundSeries": editor.backgroundSeries,
			"editor.backgroundSeries.mapPolygons.template": editor.backgroundSeries.mapPolygons.template,
			"editor.gridSeries.mapLines.template": editor.gridSeries.mapLines.template,
		};

		const exportData: { [index: string]: am5.Series } = {
			"editor.polygonSeries": editor.polygonSeries,
			"editor.lineSeries": editor.lineSeries,
			"editor.pointSeries": editor.pointSeries,
			"editor.labelSeries": editor.labelSeries,
			"editor.bubbleSeries": editor.bubbleSeries,
			"editor.gridSeries": editor.gridSeries,
		}

		// Add pixel series
		am5.object.each(editor.pixelCountrySeries, (id, pixelSeries) => {
			exportSettings["editor.pixelCountrySeries." + id] = pixelSeries;
			exportData["editor.pixelCountrySeries." + id] = pixelSeries;
		});

		let res: any = {
			settings: {},
			data: {}
		};

		am5.object.each(exportSettings, (key, entity) => {
			const obj = this.getSerialiazable(entity, {
				maxDepth: 3,
				excludeSettings: ["geoJSON", "heatRules", "zoomLevel", "target"],
				fullSettings: ["editor.bubbleTemplate", "editor.pixelTemplate", "editor"].indexOf(key + "") !== -1 ? ["userData"] : ["homeGeoPoint"]
			});
			res.settings[key] = obj.settings || {};

			if (key == "editor.map") {
				res.settings[key]["projection"] = editor.getPrivate("projection");
			}
		});

		am5.object.each(exportData, (key, entity) => {
			res.data[key] = this.getSerialiazable(entity.data, {
				maxDepth: 3,
				excludeProperties: ["editor.polygonSeries", "editor.backgroundSeries", "editor.gridSeries"].indexOf(key + "") !== -1 ? ["geometry", "geometryType", "madeFromGeoData"] : []
			}, true);

			this._decorateDataParse(res.data[key]);

			if (key == "editor.polygonSeries") {
				res.data[key] = res.data[key].filter((item: any) => {
					const keys = am5.object.keys(item);
					if (keys.length > 2) {
						return true;
					}
					return false;
				});
			}

			if (key == "editor.labelSeries") {
				res.data[key].map((item: any) => {
					if (item.pointSeries) {
						item.pointSeries = item.pointSeries.settings.id;
					}
				});
			}
		});

		//console.log(res);

		return res;
	}



	protected _decorateDataParse(data: Array<any>) {
		data.map((item: any) => {
			am5.object.each(item, (key, value: any) => {
				if (am5.type.isObject(value)) {
					if (key == "geometry") {
						(value as any).__parse = false;
					}
					else if (key == "linePointSeries") {
						this._decorateDataParse((value as any).properties.data);
					}
					item.__parse = true;
				}
			})
		});
	}

	protected getSerialiazable(source: any, settings: any = {}, full: boolean = false): any {
		const serializer = am5plugins_json.Serializer.new(this._root, settings);
		return serializer.serialize(source, 0, full);
	}

	public getJSON(source: Object, indentLevel: number = 1, maxDepth: number = 2, depth: number = 0): string {
		if (depth > maxDepth) {
			return "";
		}
		if (am5.type.isArray(source)) {
			let res = "[";
			let i = 0;
			am5.array.each(source, (val) => {
				if (i > 0) {
					res += ", ";
				}
				if (am5.type.isObject(val)) {
					res += this.getJSON(val, indentLevel, maxDepth, depth + 1);
				}
				else {
					res += val;
				}
				i++;
			});
			res += "]";
			return res;
		}
		else if (am5.type.isObject(source)) {
			let res = "{\n";
			let i = 0;
			am5.object.each(source, (key: string, val: any) => {
				if (val === undefined || (am5.type.isObject(val) && am5.object.keys(val).length == 0)) {
					return;
				}
				if (i > 0) {
					res += ",\n";
				}
				res += "\t".repeat(indentLevel) + this._maybeEscapeKey(key) + ": ";
				if (val instanceof am5.Percent) {
					res += "am5.percent(" + (val.value * 100) + ")";
				}
				else if (val instanceof am5.Color) {
					res += "am5.color(0x" + val.toCSSHex().substr(1) + ")";
				}
				else if (am5.type.isNumber(val)) {
					res += val + "";
				}
				else if (am5.type.isObject(val)) {
					res += this.getJSON(val, indentLevel + 1, maxDepth, depth + 1);
				}
				else {
					res += this._maybeEscapeValue(key, val);
				}
				i++;
			});
			res += "\n" + "\t".repeat(indentLevel - 1) + "}";
			return res;
		}
		else {
			return JSON.stringify(source);
		}
	}

	protected _maybeEscapeKey(key: string): string {
		return key.match(/[^a-z0-9]+/i) ? JSON.stringify(key) : key;
	}

	protected _maybeEscapeValue(key: string, value: any): string {
		if (["projection", "geoJSON", "target", "pointsToConnect", "linePointSeries"].indexOf(key) !== -1) {
			return value;
		}
		else {
			return JSON.stringify(value);
		}
	}

	protected _roundCoordinate(coord: number | am5.IGeoPoint): number | am5.IGeoPoint {
		return am5.type.isNumber(coord) ? am5.math.round(coord, 3) : {
			longitude: this._roundCoordinate(coord.longitude) as number,
			latitude: this._roundCoordinate(coord.latitude) as number
		};
	}

	protected _getProjectionName(projection: any): string {
		const projections = {
			"geoOrthographic": am5map.geoOrthographic(),
			"geoEquirectangular": am5map.geoEquirectangular(),
			"geoEqualEarth": am5map.geoEqualEarth(),
			"geoNaturalEarth1": am5map.geoNaturalEarth1(),
			"geoAlbersUsa": am5map.geoAlbersUsa(),
		}
		let projectionName = "";
		am5.object.eachContinue(projections, (key, val) => {
			if (val == projection) {
				projectionName = key;
				return false;
			}
			return true;
		});
		return projectionName;
	}

	public importData(json: string) {
		let promises: Promise<any>[] = [];
		const editor = this.getEditor();
		const data = JSON.parse(json);

		// Deal with legacy fuckups
		if (data.settings["editor.map"] && data.settings["editor.map"].zoomControl && data.settings["editor.map"].zoomControl.settings.target) {
			delete data.settings["editor.map"].zoomControl.settings.target;
		}

		// Geodata and projection
		const editorUserData = data.settings["editor"]["userData"];
		if (editorUserData["geodata"]) {
			promises.push(editor.loadMap(editorUserData["geodata"]));
		}

		am5.object.each(data.settings, (key: any, settings: any) => {
			if (am5.type.isObject(settings) && am5.object.keys(settings).length) {
				if (key.match(/editor\.pixelCountrySeries\./)) {
					editor.pixelCountrySeries[key.split(".").pop()] = editor._createPixelCountrySeries("", am5.color(0xffffff));
					//pixelsPresent = true;
				}

				let target: any = this._getImportTarget(key);
				if (target) {
					(settings as any).__parse = true;
					promises.push(am5plugins_json.JsonParser.new(this._root).parse(settings).then((itemSettings: any) => {
						if (key == "editor.map" && itemSettings.projection) {
							editor.setPrivate("projection", itemSettings.projection);
							itemSettings.projection = editor.getProjection(itemSettings.projection);
						}
						target.setAll(itemSettings);
						if (key == "editor.pixelTemplate") {
							editor._initPixelData(true);
						}
					}));
				}
			}
		});

		if (data.data) {

			Promise.all(promises).then(() => {
				editor.root.events.once("frameended", () => {
					editor.map.goHome();
					editor._decorateBubbleSeries(true);

					// if (pixelsPresent) {
					// 	editor._initPixelData(true);
					// }

					am5.object.each(data.data, (key: any, data: any) => {
						if (am5.type.isArray(data) && data.length) {
							let target: any = this._getImportTarget(key);
							if (target) {
								target.data.clear();
								promises.push(am5plugins_json.JsonParser.new(this._root).parse(data).then((itemData: any) => {
									target.data.pushAll(itemData);

									// Special treatment
									// Line series
									if (key == "editor.lineSeries") {
										am5.array.each(target.dataItems, (dataItem: any) => {
											const pointSeries = dataItem.dataContext.linePointSeries;
											if (pointSeries) {
												editor._decorateLinePointSeries(pointSeries, pointSeries.get("id") ? false : true);
												editor.linePointSeries.push(pointSeries);
												editor.map.series.push(pointSeries);
												dataItem.set("pointsToConnect", pointSeries.dataItems);
											}
										});
									}
								}));
							}
						}
					});

					// Set up heat rules
					if (data.settings["editor"].heatActive) {
						editor.setPolygonHeat(true, editor.polygonSeries);
					}

					editor.root.events.once("frameended", () => {

						// Set up point-attached labels
						am5.array.each(editor.labelSeries.dataItems, (labelDataItem: any) => {
							const label = labelDataItem.dataContext;
							if (label.pointSeries) {
								label.pointSeries = am5.registry.entitiesById[label.pointSeries];
								const pointDataItem = label.pointSeries.getDataItemById(label.pointId);
								if (labelDataItem) {
									editor._linkPointLabel(pointDataItem, labelDataItem);
									editor._positionPointLabel(labelDataItem);
								}
							}
						});

						// Set up point animations
						am5.array.each(editor.pointSeries.dataItems, (pointDataItem: any) => {
							const point = pointDataItem.dataContext;
							if (point.lineId) {
								const lineDataItem = editor.lineSeries.getDataItemById(point.lineId);
								pointDataItem.set("lineDataItem", lineDataItem);
								pointDataItem.set("positionOnLine", point.positionOnLine);
								pointDataItem.set("autoRotate", point.autoRotate);
								if (point.animationDuration) {
									editor.setPointAnimation(pointDataItem, point.animationDuration, point.animationFlip);
								}
							}
						});
					});

				});
			});

		}

		return promises;

	}

	protected _getImportTarget(key: string, forceCreate: boolean = false): am5.Entity | undefined {
		const editor = this.getEditor();
		const path = key.split(".");
		let target: any;
		am5.array.each(path, (step: string) => {
			if (step == "editor") {
				target = editor;
			}
			else if (target[step] !== undefined) {
				target = target[step];
			}
			else if (forceCreate) {
				target[step] = {};
				target = target[step];
			}
		});
		return target;
	}

	public getEditor(): MapViewer {
		return this.get("editor");
	}

	public storageAvailable(): boolean {
		return localStorage ? true : false;
	}

	public setStorage(id: string, value: string): void {
		if (this.storageAvailable()) {
			localStorage.setItem(id, value);
		}
	}

	public getStorage(id: string): any {
		if (this.storageAvailable()) {
			return localStorage.getItem(id);
		}
	}

	public removeStorage(id: string): any {
		if (this.storageAvailable()) {
			localStorage.removeItem(id);
		}
	}

}